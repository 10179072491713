import { useQuery } from "@tanstack/react-query";
import { usePViContext } from "../../../contexts/PViContext";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQPVi_PropertyHistory = () => {
   const { sosAxios } = useSosAxios();
   const { pviState } = usePViContext();
   const { pid } = pviState;
   return useQuery({
      enabled: !!pid,
      refetchOnMount: true,
      refetchInterval: 1000 * 60,
      queryKey: ["pvi/property-history", pid],
      queryFn: () => sosAxios.get(`/property-viability/properties/${pid}/history`).then<Response>(({ data }) => data),
   });
};
type Response = PVi_GetPropertyHistory_Response_Dto;
export type useQPVi_PropertyHistory_Audit = PVi_GetPropertyHistory_Response_Audit;

class PVi_GetPropertyHistory_Response_Dto {
   audits!: PVi_GetPropertyHistory_Response_Audit[];
}
class PVi_GetPropertyHistory_Response_Audit {
   daid!: number;
   column!: string;
   created_at!: string;
   old_value!: string;
   new_value!: string;
   user_name!: string | null;
   user_email!: string;
}
