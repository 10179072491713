import { SHrPopup, SHrSosButton, SHrSosTabsBar } from "@simplyhomes/react";
import classNames from "classnames/bind";
import { useState } from "react";
import styles from "./PViPropertyDetailsTabs.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { PVipropertyDetailsTab } from "./PVipropertyDetailsTab/PVipropertyDetailsTab";
import { PViUnitDetailsTab } from "./PViUnitDetailsTab/PViUnitDetailsTab";
import { PViViewHistoryModal } from "../PViViewHistoryModal/PViViewHistoryModal";
import { PViPropertyDetailsTabOverview } from "../../PViPropertyDetailsTabOverview/PViPropertyDetailsTabOverview";
import { PViAreaTab } from "./PViAreaTab/PViAreaTab";
import { PViTaxHistory } from "./PViTaxHistory/PViTaxHistory";
import { PViUnderwriting } from "./PViUnderwriting/PViUnderwriting";
import { PViSource } from "./PViSource/PViSource";

const cx = classNames.bind(styles);

const tabs = ["Overview", "Property Details", "Unit Details", "Area", "Tax History", "Underwriting", "Source", "Leads"];

export const PViPropertyDetailsTabs = () => {
   const [isOpenViewHistoryModal, setIsOpenViewHistoryModal] = useState(false);

   const [activeTab, setActiveTab] = useState("Overview");

   return (
      <div className={cx("container")}>
         {/* <SHrPopup show={isOpenViewHistoryModal}></SHrPopup> */}
         <div className={cx("leftC")}>
            <div className={cx("tabAndHistoryC")}>
               <SHrSosTabsBar tabs={tabs} value={activeTab} onChange={setActiveTab} />
               <div className={cx("btnGroupC")}>
                  <SHrPopup
                     type="resize-right"
                     show={isOpenViewHistoryModal}
                     handleShow={setIsOpenViewHistoryModal}
                     triggerElement={
                        <SHrSosButton type="text" buttonProps={{ className: cx("btn", "viewHistory") }}>
                           <img src={CF_CDN_URL("/assets/property_viability/history_info500.svg")} alt="" />
                           <span>View history</span>
                        </SHrSosButton>
                     }
                  >
                     <PViViewHistoryModal toggle={() => setIsOpenViewHistoryModal(!isOpenViewHistoryModal)} />
                  </SHrPopup>

                  {/* <SHrSosButton type="text" buttonProps={{ className: cx("btn", "lock") }}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/property_viability/lock_negative500.svg")} alt="" />
                     </div>
                     Lock data
                  </SHrSosButton>

                  <SHrSosButton type="positive" buttonProps={{ className: cx("btn") }}>
                     Save changes
                  </SHrSosButton> */}
               </div>
            </div>
            <div className={`${styles.pd}`}>
               {(activeTab === "Overview" && <PViPropertyDetailsTabOverview />) ||
                  (activeTab === "Property Details" && <PVipropertyDetailsTab />) ||
                  (activeTab === "Unit Details" && <PViUnitDetailsTab />) ||
                  (activeTab === "Area" && <PViAreaTab />) ||
                  (activeTab === "Tax History" && <PViTaxHistory />) ||
                  (activeTab === "Underwriting" && <PViUnderwriting />) ||
                  (activeTab === "Source" && <PViSource />)}
            </div>
         </div>
      </div>
   );
};
