import styles from "./DevJim.module.scss";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSConfirmWrapper } from "../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSInputSelect, TSoSInputSelect_option } from "../../components/common/SoSInputSelect/SoSInputSelect";
import { useState } from "react";
import { SoSDataPoint } from "../../components/SoSDataPoint/SoSDataPoint";
import {
   database_helpers_createPayloadHelper as db,
   database_helpers_createPayloadHelper as genDbCb,
} from "../../hooks/database/mutations/utils";
import { useMDatabase_UploadFiles } from "../../hooks/database/mutations/useMDatabase_UploadFiles";
import { useMDatabase_DownloadFile } from "../../hooks/database/mutations/useMDatabase_DownloadFile";
import { TPgSoS_SoSUtilitiesAllowances_Schema_Data } from "@simplyhomes/utils/dist/types/pg/sos/utilities-allowances";
import { TPgSoS_SoSUnits_Schema_UtilitiesConfig } from "@simplyhomes/utils/dist/types/pg/sos/units";
// import DocViewer from "react-doc-viewer";
export const DevJim = () => {
   const data = {} as TPgSoS_SoSUtilitiesAllowances_Schema_Data;
   const config = [{ groupTitle: "Heating", lineTitle: "Natural Gas" }] as TPgSoS_SoSUnits_Schema_UtilitiesConfig;
   const responsibility = {
      gas: true,
      sewer: true,
      trash: true,
      water: true,
      appliances: false,
      electricity: true,
   };
   const bed = 3;
   // if (!data)return null
   const costPerType = config.reduce(
      (prev: Record<string, { checked: boolean; cost: number }>, { groupTitle, lineTitle }) => {
         const lineItem = data[groupTitle]?.lineItems.find(({ title }) => title === lineTitle);
         if (!lineItem) return prev;
         const lineCost = lineItem.allowances[bed] || 0;
         const lineType = lineItem.type;
         return {
            ...prev,
            [lineType]: {
               checked: responsibility[lineType],
               cost: prev[lineType]?.cost ? prev[lineType].cost + lineCost : lineCost,
            },
         };
      },
      Object.entries(responsibility).reduce((prev, [type, checked]) => ({ ...prev, [type]: { cost: 0, checked } }), {})
   );

   return (
      <div className={`${styles.contentC}`}>
         {Object.entries(costPerType).map(([type, { checked, cost }]) => (
            <div key={type}></div>
         ))}

         <TestFileUpload />
         <TestFileDownload />
         <div className={`${styles.hidden}`}>
            <TestDataPoint />
            <TestInputSelectTags />
            <TestConfirmWrapper />
         </div>
      </div>
   );
};

const TestDataPoint = () => {
   const [value, setValue] = useState(["option0", "option1", "option0", "option0", "option0"]);
   // const db = genDbCb("sos_properties", [{ column: "pid", id: "1" }], () => {});
   const db = genDbCb("sos_leads", [{ column: "lid", id: "80" }]);
   const options = [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
   ];
   return (
      <div>
         <SoSDataPoint
            options_isMulti
            options={options}
            title="Test"
            type="bold-title"
            value={value}
            database={db("next_actions")}
            buttons={[{ title: "Test", onClick: () => alert("Test"), loading: false }]}
         />
      </div>
   );
};

const TestFileUpload = () => {
   const [files, setFiles] = useState<FileList | null>(null);
   const db_P = db("sos_properties", [{ column: "pid", id: "ChIJ1TYiDHPQNIgRTDK3xPmzJfw" }]);
   const mUploadFiles = useMDatabase_UploadFiles(db_P("scope_of_work"));
   const handleUpload = () => {
      if (!files) return;
      mUploadFiles.mutate({ files });
   };
   return (
      <div>
         <input title="file-upload" type="file" onChange={(e) => setFiles(e.target.files)} />
         <div>{files?.length} files selected</div>
         <button title="upload" onClick={handleUpload} type="button">
            Upload
         </button>
      </div>
   );
};

const TestFileDownload = () => {
   const mDownloadFile = useMDatabase_DownloadFile({ fuid: 1, fileNameAndExtension: "test.svg" });
   return (
      <div>
         <div>Is Downloading: {(!!mDownloadFile.isMutating).toString()}</div>
         <button type="button" title="download" onClick={() => mDownloadFile.mutate()}>
            Download File
         </button>
      </div>
   );
};

const TestInputSelectTags = () => {
   const [selected, setSelected] = useState<TSoSInputSelect_option[]>([]);
   const options: TSoSInputSelect_option[] = [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
   ];
   return (
      <div>
         <SoSInputSelect isMulti value={selected} onChange={setSelected} options={options} />
      </div>
   );
};

const TestConfirmWrapper = () => (
   <SoSConfirmWrapper type="default" onCancel={() => alert("Cancelled")} onConfirm={() => alert("CONFIRMED")}>
      <SHrSosButton type="dangerous" buttonProps={{ onClick: () => alert("DO NOT USE ONCLICK ON CHILDREN") }}>
         Delete
      </SHrSosButton>
   </SoSConfirmWrapper>
);
