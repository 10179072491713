import { SHrPopup } from "@simplyhomes/react";
import { usePViContext } from "../../../contexts/PViContext";
import { PViPropertyDetails } from "../PViPropertyDetails/PViPropertyDetails";
/**
 * !WARN: NEED PViContext
 */
export const PViPropertyDetailsModal = () => {
   const { pviState, pviOverwrite } = usePViContext();
   const { showDetailsModal } = pviState;

   return (
      <SHrPopup
         show={showDetailsModal}
         handleShow={(b) => pviOverwrite({ showDetailsModal: b, excludedPropertyIds: [], pid: b ? undefined : "" })}
      >
         <PViPropertyDetails />
      </SHrPopup>
   );
};
