import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";

export const useM_Convo_CreateCommentInThread = ({ onSuccess, onError }: TUseMConvo_CreateCommentInThreadProps) => {
   const { sosAxios } = useSosAxios();

   return useMutation({
      onSuccess,
      onError,
      mutationFn: ({ tid, content }: CreateCommentInThread) =>
         sosAxios
            .post(`/conversations/threads/${tid}/comments`, { content })
            .then<TConversations_CreateCommentInThread_Response>(({ data }) => data),
   });
};

export type CreateCommentInThread = {
   tid: number;
   content: string;
};

type TUseMConvo_CreateCommentInThreadProps = {
   onSuccess: (data: TConversations_CreateCommentInThread_Response) => void;
   onError: (message: string) => void;
};

export type TConversations_CreateCommentInThread_Response = {
   cid: number;
};
