import classNames from "classnames/bind";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NavHorizontal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useSosAuth } from "../../../hooks/auth/useSosAuth";
import { useQSOS_Profile } from "../../../hooks/queries/user/useQSOS_Profile";

const cx = classNames.bind(styles);
const NavHorizontal = () => {
   const nav = useNavigate();

   const { logOut } = useSosAuth();

   const qUserProfile = useQSOS_Profile();

   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

   const { data: userProfile } = qUserProfile;

   const profile = userProfile?.profile;
   if (!profile) return null;

   const { picture } = profile;

   const userMenuItems: { icon: string; title: string; cb: () => void }[] = [
      { icon: "person_primary.svg", title: "My Profile", cb: () => nav("/profile") },
      { icon: "settings_primary.svg", title: "Account settings", cb: () => nav("/account-settings") },
      { icon: "help_center_primary.svg", title: "Support center", cb: () => nav("/support-center") },
      { icon: "exit_to_app_primary.svg", title: "Sign out", cb: logOut },
   ];

   return (
      <div className={cx("contentC")}>
         <div className={cx("logo")}>
            <img src={CF_CDN_URL("/assets/nav_logo.svg")} alt="" />
            <span>Simply OS</span>
         </div>

         <div className={cx("notification")}>
            <img src={CF_CDN_URL("/assets/notifications_snow.svg")} alt="" />
            <div className={cx("avt-notification")} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
               <div>{profile?.picture && <img src={picture} alt="" />}</div>
               <img src={CF_CDN_URL("/assets/arrow_drop_down.svg")} alt="" />
            </div>
            <ul
               className={cx("menu-item", { open: isDropdownOpen })}
               onMouseLeave={() => setIsDropdownOpen(false)}
               onClick={() => setIsDropdownOpen(false)}
            >
               {userMenuItems.map((item, index) => (
                  <li key={index + item.title} onClick={item.cb}>
                     <img src={CF_CDN_URL(`/assets/${item.icon}`)} alt="" />
                     <span>{item.title}</span>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
};

export default NavHorizontal;
