import classNames from "classnames/bind";
import { useState } from "react";
import { SHrPopup, SHrSosButton, SHrSosTabsBar, SHrSpinner } from "@simplyhomes/react";

import styles from "./PViConversation.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SoSInputTitle } from "../../../../components/common/soSInputTitle/SoSInputTitle";
import { PViThreadCard } from "./PViThreadCard/PViThreadCard";
import { SoSInputSelect } from "../../../../components/common/SoSInputSelect/SoSInputSelect";
import { PViCreateNewThreadModal } from "./PViCreateNewThreadModal/PViCreateNewThreadModal";
import { useQCon_getThreadsByPropertyId } from "../../../../hooks/conversation-api/queries/threads/useQCon_getThreadsByPropertyId";
import { useSoSOptions_Convo_Thread_Tags } from "../../../../hooks/queries/options/useQSoS_Options";
import { useConvoQueryParam } from "../../../../hooks/conversation-api/hooks/useConvoQueryParam";

const cx = classNames.bind(styles);

const tabsRight = ["Conversation", "Seller outreach"];
export const PViConversation = () => {
   const [tabRightSelected, setTabRightSelected] = useState<string>(tabsRight[0]);
   return (
      <div className={cx("container", "_jr_pviConversation")}>
         <SHrSosTabsBar tabs={tabsRight} value={tabRightSelected} onChange={setTabRightSelected} />

         {tabRightSelected === "Conversation" && <Conversation />}
         {tabRightSelected === "Seller outreach" && <div />}
      </div>
   );
};

const Conversation = () => {
   // const [q, setQ] = useState<string>("");

   const qpConvo = useConvoQueryParam();

   const { q, tags } = qpConvo;

   const { setQ, setTags } = qpConvo;

   const [isOpenCreateNewThreadModal, setIsOpenCreateNewThreadModal] = useState(false);

   const qPViConvGetThreads = useQCon_getThreadsByPropertyId();

   const { threads = [] } = qPViConvGetThreads;
   const qGetConvoTags = useSoSOptions_Convo_Thread_Tags();

   if (!qGetConvoTags.data?.options) return null;
   const tagOptions = qGetConvoTags.data?.options.map((o) => ({
      label: o.label,
      value: o.value,
   }));

   return (
      <div className={cx("conversationC")}>
         <div className={cx("btnNotifyAndSlack")}>
            <SHrSosButton
               buttonProps={{ className: cx("btn", "white"), onClick: () => setIsOpenCreateNewThreadModal(true) }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/edit_black.svg")} alt="" />
               </div>
               New thread
            </SHrSosButton>

            <SHrSosButton type="elevated" buttonProps={{ className: cx("btn"), onClick: () => {} }}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/social_slack.svg")} alt="" />
               </div>
               Link to Slack
            </SHrSosButton>
         </div>
         <div className={cx("input_search")}>
            <SoSInputTitle
               value={q}
               type="text"
               handleValue={setQ}
               icon="search"
               placeholder="Search in conversation"
            />
         </div>
         <div className={cx("filterConversationC")}>
            <div className={cx("filterC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/local_offer_black.svg")} alt="" />
               </div>
               <div className={cx("input-search")}>
                  <SoSInputSelect
                     placeholder="Filter"
                     isMulti
                     value={tagOptions
                        .filter((o) => tags.includes(o.value))
                        .map((o) => ({ label: o.label, value: o.value }))}
                     onChange={(op) => setTags(op.map((v) => v.value))}
                     options={tagOptions}
                  />
               </div>
            </div>
            {/* <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div> */}
         </div>

         {/* <div className={cx("tagsC")}>
            {tags.map((t, i) => (
               <FilterTag key={i} tag={t} />
            ))}
         </div> */}

         <div className={cx("threadsC")}>
            {qPViConvGetThreads.isLoading ? (
               <div className={cx("spinnerC")}>
                  <SHrSpinner />
                  Loading...
               </div>
            ) : threads.length === 0 ? (
               <div className={cx("noThread")}>No thread</div>
            ) : (
               <div className={cx("threadList")}>
                  {threads.map((thread, i) => (
                     <PViThreadCard key={i} tid={thread.tid} />
                  ))}
                  <div className={cx("footer")}>
                     <SHrSosButton
                        buttonProps={{
                           onClick: () => qPViConvGetThreads.fetchNextPage(),
                           className: cx("load-more", {
                              hidden: !qPViConvGetThreads.hasNextPage || qPViConvGetThreads.isFetchingNextPage,
                           }),
                        }}
                     >
                        Load More
                     </SHrSosButton>
                  </div>
               </div>
            )}
         </div>

         <SHrPopup show={isOpenCreateNewThreadModal} handleShow={(s) => setIsOpenCreateNewThreadModal(s)}>
            <PViCreateNewThreadModal onClose={() => setIsOpenCreateNewThreadModal(false)} />
         </SHrPopup>
      </div>
   );
};

// type FilterTagProps = {
//    tag: string;
// };
// const FilterTag = ({ tag }: FilterTagProps) => {
//    return (
//       <div className={cx("filterTag")}>
//          <span>{tag}</span>
//          <div className={cx("img-icon")}>
//             <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
//          </div>
//       </div>
//    );
// };
