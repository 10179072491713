import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";

export const useMConvo_ThreadSubscription = ({ onSuccess, onError }: TUseMConvo_ThreadSubscriptionProps) => {
   const { sosAxios } = useSosAxios();

   return useMutation({
      onSuccess,
      onError,
      mutationFn: ({ tid, subscribed }: TSoS_Convo_ThreadSubscription_Props) =>
         sosAxios
            .patch(`/conversations/threads/${tid}/subscriptions`, { subscribed })
            .then<TSoS_Convo_ThreadSubscription_Response>(({ data }) => data),
   });
};

type TSoS_Convo_ThreadSubscription_Props = {
   tid: number;
   subscribed: boolean;
};

type TUseMConvo_ThreadSubscriptionProps = {
   onSuccess: (data: TSoS_Convo_ThreadSubscription_Response) => void;
   onError: (message: string) => void;
};

type TSoS_Convo_ThreadSubscription_Response = { message: string };
