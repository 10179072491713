import classNames from "classnames/bind";
import styles from "./PViUtilitiesAllowanceModal.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { SoSDataPoint } from "../../../../components/SoSDataPoint/SoSDataPoint";
import { SoSCheckbox } from "../../../../components/common/SoSCheckbox/SoSCheckbox";
import {
   PVi_GetPropertyById_Unit,
   useQPVi_Property,
} from "../../../../hooks/property-viability/queries/useQPVi_Property";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../hooks/database/mutations/utils";
import { useMDatabase_UpdateValue } from "../../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { useEffect, useState } from "react";
import { useDebounceCallback } from "../../../../hooks/react/useDebounceCallback";

const cx = classNames.bind(styles);

export const PViUtilitiesAllowanceModal = ({ onClose, unit }: TPViUtilitiesAllowanceModalProps) => {
   const qPVi_Property = useQPVi_Property();

   const [responsibility, setResponsibility] = useState(unit.utilities_tenant_responsibility);
   useEffect(() => {
      if (unit.utilities_tenant_responsibility) setResponsibility(unit.utilities_tenant_responsibility);
   }, [unit.utilities_tenant_responsibility]);

   const { property: p } = qPVi_Property.data || {};

   const db_U = dbcb("sos_units", [{ column: "uid", id: unit.uid }], () => qPVi_Property.refetch());

   const mDb_U_responsibility = useMDatabase_UpdateValue(db_U("utilities_tenant_responsibility"));

   const { debouncedCallback: dbnedMutateResponsibility } = useDebounceCallback(
      (newRes: typeof responsibility) => mDb_U_responsibility.mutate({ value: JSON.stringify(newRes) }),
      2000
   );

   if (!p || !responsibility) return null;

   const costPerType: Record<string, { checked: boolean; cost: number }> = unit.utilities_config.reduce(
      (prev: Record<string, { checked: boolean; cost: number }>, { groupTitle, lineTitle }) => {
         const lineItem = p.utilities_allowances[groupTitle]?.lineItems.find(({ title }) => title === lineTitle);
         if (!lineItem) return prev;
         const lineCost = lineItem.allowances[unit.bed] || 0;
         const lineType = lineItem.type;
         return Object.entries({
            ...prev,
            [lineType]: {
               checked: responsibility?.[lineType],
               cost: prev[lineType]?.cost ? prev[lineType].cost + lineCost : lineCost,
            },
         })
            .sort(([a], [b]) => a.localeCompare(b))
            .reduce((prev, [type, { checked, cost }]) => ({ ...prev, [type]: { checked, cost } }), {});
      },
      Object.entries(responsibility)
         .sort(([a], [b]) => a.localeCompare(b))
         .reduce((prev, [type, checked]) => ({ ...prev, [type]: { cost: 0, checked } }), {})
   );

   const handleCheckBox = (type: string, checked: boolean) => {
      const newRes = { ...responsibility, [type]: checked };
      setResponsibility(newRes);
      dbnedMutateResponsibility(newRes);
   };

   return (
      <div className={cx("container")}>
         <div className={cx("heading")}>
            <span>Utility Allowance</span>

            {/* <SHrSosButton type="text" buttonProps={{ className: cx("btn", "reset"), onClick: () => {} }}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/restore_black.svg")} alt="" />
               </div>
               Reset
            </SHrSosButton> */}

            {/* <SHrSosButton type="outlined" buttonProps={{ className: cx("btn"), onClick: () => {} }}>
               Download PDF
            </SHrSosButton>

            <SHrSosButton buttonProps={{ className: cx("btn"), onClick: () => {} }}>Save</SHrSosButton> */}

            <div className={cx("img-icon")} onClick={() => onClose()}>
               <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
            </div>
         </div>

         <div className={cx("bodyC")}>
            <div className={cx("leftC")}>
               <div className={cx("topTitleC")}>
                  <b>Identified Utility Allowances</b>
               </div>
               <div className={cx("title")}>
                  <span>
                     Property Type: <b>{p.property_type}</b>
                  </span>
                  <span>
                     <b>{unit.bed > 1 ? `${unit.bed} beds` : `${unit.bed} bed`}</b>
                  </span>
               </div>

               {Object.entries(p.utilities_allowances).map(([groupTitle, groupData], i) => (
                  <div key={i} className={cx("wrapper")}>
                     <div className={cx("titleLine")}>{groupTitle}</div>
                     {groupData?.lineItems
                        .filter((item) =>
                           unit.utilities_config.find(
                              (config) => config.groupTitle === groupTitle && config.lineTitle === item.title
                           )
                        )
                        .map((val, j) => (
                           <div key={j} className={cx("value", "borderR")}>
                              <span>{val.title}</span>
                              <span>
                                 <b>{SHu_js_currencyFormat(val.allowances[unit.bed])}</b>
                              </span>
                           </div>
                        ))}
                  </div>
               ))}
            </div>
            <div className={cx("rightC")}>
               <div className={cx("dataTotalC")}>
                  <SoSDataPoint
                     title="Simply Homes Utility Expense"
                     type={"bold-value"}
                     value={unit.utilities_simply_paying}
                     format="currency"
                  />
               </div>

               <div className={cx("dataTotalC")}>
                  <SoSDataPoint
                     title="Tenant Utility Expense"
                     type={"bold-value"}
                     value={unit.utilities_tenant_paying}
                     format="currency"
                  />
               </div>

               <div className={cx("tenantResponsibleC")}>
                  <div className={cx("header")}>
                     {(mDb_U_responsibility.isPending || qPVi_Property.isFetching) && <SHrSpinner />}
                     <span>Tenant Responsible</span>
                  </div>

                  {costPerType &&
                     Object.entries(costPerType).map(([type, { checked, cost }]) => (
                        <div key={type} className={cx("dataCheckboxC")}>
                           <SoSDataPoint
                              title={type.toUpperCase()}
                              type={"bold-value"}
                              value={cost}
                              format="currency"
                           />
                           <SoSCheckbox value={checked} onChange={() => handleCheckBox(type, !checked)} />
                        </div>
                     ))}
               </div>
            </div>
         </div>
      </div>
   );
};

type TPViUtilitiesAllowanceModalProps = {
   onClose: () => void;
   unit: PVi_GetPropertyById_Unit;
};
