import { useQueryParams } from "../../react/useQueryParams";

export const useConvoQueryParam = () => {
   const { useSingleQueryParamsState, useMultiQueryParamsState } = useQueryParams();
   const [limit] = useSingleQueryParamsState("limit", "50");
   const [tags, setTags] = useMultiQueryParamsState("tags", []);
   const [q, setQ] = useSingleQueryParamsState("q", "");

   const filters = { tags, limit, q };
   return {
      filters,
      ...filters,
      tags,
      setTags,
      setQ,
   };
};
