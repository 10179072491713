import classNames from "classnames/bind";
import styles from "./PViTaxHistory.module.scss";
import { SoSDataPoint } from "../../../../../components/SoSDataPoint/SoSDataPoint";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../hooks/database/mutations/utils";

const cx = classNames.bind(styles);

export const PViTaxHistory = () => {
   const qPVi_Property = useQPVi_Property();

   const { property: p } = qPVi_Property.data || {};
   if (!p) return null;

   const db_P = dbcb("sos_properties", [{ column: "pid", id: p.pid }], () => qPVi_Property.refetch());
   return (
      <div className={cx("container")}>
         <div className={cx("infoC")}>
            <SoSDataPoint type="bold-title" value={"Parcel Number"} title="Parcel Number" isMocked />
            <SoSDataPoint type="bold-title" value={"Situs County"} title="Situs County" isMocked />
            <SoSDataPoint
               type="bold-title"
               value={"Primary Owner Full Name"}
               title="Primary Owner Full Name"
               isMocked
            />
         </div>
         <div className={cx("infoC")}>
            <SoSDataPoint
               type="bold-title"
               value={"Tax Bill Mailing Address"}
               title="Tax Bill Mailing Address"
               isMocked
            />
            <SoSDataPoint type="bold-title" value={"Tax Year"} title="Tax Year" isMocked />
            <SoSDataPoint type="bold-title" value={"Tax Amount"} title="Tax Amount" isMocked />
         </div>
         <div className={cx("infoC")}>
            <SoSDataPoint type="bold-title" value={"Total Assessed Value"} title="Total Assessed Value" isMocked />
            <SoSDataPoint type="bold-title" value={"Land Value"} title="Land Value" isMocked />
            <SoSDataPoint type="bold-title" value={"Improvement Value"} title="Improvement Value" isMocked />
         </div>
      </div>
   );
};
