import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useMContacts_CreateConstact = ({ onSuccess, onError }: TUseMContacts_CreateConstactProps) => {
   const { sosAxios } = useSosAxios();

   return useMutation({
      onSuccess,
      onError,
      mutationFn: (createContactProps: TSoS_CreateContact_Props) =>
         sosAxios.post(`/contacts`, createContactProps).then<TSoS_Contacts_CreateContact_Response>(({ data }) => data),
   });
};
type TUseMContacts_CreateConstactProps = {
   onSuccess: (data: TSoS_Contacts_CreateContact_Response) => void;
   onError: (message: string) => void;
};
type TSoS_CreateContact_Props = {
   email: string;
   phone?: string;
   name?: string;
};
export type TSoS_Contacts_CreateContact_Contact = {
   cid: number;
};

export type TSoS_Contacts_CreateContact_Response = { contact: TSoS_Contacts_CreateContact_Contact };
