import classNames from "classnames/bind";
import styles from "./PViAddNewModelModal.module.scss";
import { SoSConfirmWrapper } from "../../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SoSInputTitle } from "../../../../components/common/soSInputTitle/SoSInputTitle";
import { SoSSwich } from "../../../../components/common/SoSSwich/SoSSwich";
import { useState } from "react";

import { useQPVi_Property } from "../../../../hooks/property-viability/queries/useQPVi_Property";
import { useMDb_P_UpdateUwModels } from "../../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { PgSoS_SoSProperties_UnderwritingModel } from "@simplyhomes/utils/dist/types/pg/sos/properties";

const cx = classNames.bind(styles);

type PViNewRentTypeModalProps = {
   onClose: () => void;
};
export const PViAddNewModelModal = ({ onClose }: PViNewRentTypeModalProps) => {
   const qProperty = useQPVi_Property();
   const { pid, underwriting_models } = qProperty.data?.property || {};

   const mDb_P_uwModels = useMDb_P_UpdateUwModels({ pid });

   const [model, setModel] = useState<PgSoS_SoSProperties_UnderwritingModel>({
      name: "",
      settings: { vacancy_percentage: 0.03, simply_paying_utils: false },
   });

   const handleAddModel = () => {
      onClose();
      const newModels = [];
      if (underwriting_models) newModels.push(...underwriting_models);
      newModels.push(model);
      mDb_P_uwModels.mutate({ value: JSON.stringify(newModels) });
   };

   const handleSettings = (p: Partial<PgSoS_SoSProperties_UnderwritingModel["settings"]>) =>
      setModel({ ...model, settings: { ...model.settings, ...p } });

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/add_box_black.svg")} alt="" />
            </div>
            <span>New Rent Type</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("contentC")}>
            <div className={cx("inputFieldC")}>
               <SoSInputTitle
                  title="Model Name"
                  placeholder="Custom rent"
                  handleValue={(value) => setModel({ ...model, name: value })}
                  value={model.name}
                  type={"text"}
               />
            </div>
            <div className={cx("inputFieldC")}>
               <SoSInputTitle
                  title="Vacancy Percentage"
                  placeholder="Enter an number"
                  inputProps={{
                     min: 0,
                  }}
                  handleValue={(v) => handleSettings({ vacancy_percentage: +v })}
                  value={model.settings.vacancy_percentage || ""}
                  type={"number"}
               />
            </div>

            <hr className={cx("divide")} />

            <div className={cx("switchOptionC")}>
               <TitleAndCaption title={"Simply Paying Utils"} />
               <SoSSwich
                  checked={model.settings.simply_paying_utils || false}
                  onChange={(v) => handleSettings({ simply_paying_utils: v })}
               />
            </div>
         </div>

         <SoSConfirmWrapper
            title="Are you sure you want to create rent type?"
            content="This action allows to create rent type."
            type="default"
            onConfirm={handleAddModel}
         >
            <div className={cx("btnSave")}>
               <SHrSosButton
                  buttonProps={{ className: cx("btn"), disabled: !model.name }}
                  loading={mDb_P_uwModels.isPending}
               >
                  Save
               </SHrSosButton>
            </div>
         </SoSConfirmWrapper>
      </div>
   );
};

type TitleAndCaptionProps = {
   title: string;
};

const TitleAndCaption = ({ title }: TitleAndCaptionProps) => {
   return (
      <div className={cx("wrapper")}>
         <div className={cx("titleC")}>
            <span>{title}</span>
            <div className={cx("img-icon")}>
               <img className={cx({})} src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
            </div>
         </div>
         <span className={cx("caption")}>Monthly & Annually</span>
      </div>
   );
};
