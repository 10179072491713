import classNames from "classnames/bind";
import { SHrSosButton } from "@simplyhomes/react";
import { useEffect, useMemo, useState } from "react";

import styles from "./OffersCard.module.scss";

import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
import { SoSRoudedTabs } from "../../../../../components/common/SoSRoudedTabs/SoSRoudedTabs";
import { SoSInputTitle } from "../../../../../components/common/soSInputTitle/SoSInputTitle";
import { useQOffers_Lead } from "../../../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { OffersTermPoint } from "../../OffersTerms/OffersTermPoint/OffersTermPoint";
import { useToastContext } from "../../../../../contexts/ToastContext";
import { useOffersContext } from "../../../../../contexts/OffersContext";
import { useMOffers_UpdateOffer } from "../../../../../hooks/offers/mutates/offer/useMOffers_UpdateOffer";
import { OffersPropertiesSelection } from "../../../OffersPropertiesSelection/OffersPropertiesSelection";
import {
   useSoSOptions_Offers_OfferStatuses,
   useSoSOptions_Offers_OfferTypes,
   useSoSOptions_Offers_Simply_Terms,
} from "../../../../../hooks/queries/options/useQSoS_Options";
import { useQOffers_GetLeads } from "../../../../../hooks/offers/queries/leads/useQOffers_GetLeads";

const cx = classNames.bind(styles);

const tabs = ["Sent", "Countered", "Accepted", "Rejected"];

type TOfferCardProps = { oid: number };

export const OfferCard = ({ oid }: TOfferCardProps) => {
   const { toastDispatch } = useToastContext();
   const { offersDispatch } = useOffersContext();

   const qOfferStatusesOptions = useSoSOptions_Offers_OfferStatuses();

   const offerStatuses = useMemo(
      () => qOfferStatusesOptions.data?.options || [],
      [qOfferStatusesOptions.data?.options]
   );

   const qOffersGetLead = useQOffers_Lead();
   const qOffersGetLeads = useQOffers_GetLeads();
   const qOffersTermTemplates = useSoSOptions_Offers_Simply_Terms();
   const qOfferType = useSoSOptions_Offers_OfferTypes();

   const offer = oid > 0 ? qOffersGetLead.data?.lead.offersMap[oid] : undefined;

   const [activeTab, setActiveTab] = useState(offer?.status || "");

   const { mutate } = useMOffers_UpdateOffer({
      onSuccess: () => {
         qOffersGetLead.refetch();
         qOffersGetLeads.refetch();
         offersDispatch({ overwrite: { showCreateAndUpdateOfferModal: false } });
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Edit offer is successful!",
            },
         });
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Edit offer is failed!",
            },
         }),
   });

   useEffect(() => {
      const activeTabFound = offerStatuses.find((os) => os.value === offer?.status);
      if (!activeTabFound) return;
      setActiveTab(activeTabFound.label);
   }, [offer?.status, offerStatuses]);

   if (!offer) return null;

   const { properties, terms, type } = offer;

   const handleUpdatestatusOffer = (newStatus: string) => {
      mutate({
         oid,
         status: newStatus,
      });
   };

   return (
      <div className={cx("offerContentC")}>
         <div className={cx("detailsC")}>
            <div className={cx("quickInfoContainer")}>
               <div className={cx("quickInfoWrapper")}>
                  <div className={cx("infoC")}>
                     <span>Expire on</span>
                     <span className={cx("fs-18")}>
                        <b>23 Jun 2024</b>
                     </span>
                  </div>
                  <div className={cx("infoC")}>
                     <span>Type</span>
                     <span className={cx("fs-18")}>
                        <b>{qOfferType.data?.options.find((o) => o.value === type)?.label}</b>
                     </span>
                  </div>
                  <div className={cx("infoC")}>
                     <span>Next Action</span>
                     <span className={cx("fs-18")}>
                        <b>Email Contract</b>
                     </span>
                  </div>
               </div>

               <div className={cx("statusC")}>
                  <span>Offer Status</span>
                  <SoSRoudedTabs
                     tabs={tabs}
                     value={activeTab}
                     onChange={(newTab) => {
                        setActiveTab(newTab);
                        handleUpdatestatusOffer(newTab);
                     }}
                  />
               </div>
            </div>
            <div className={cx("termsC")}>
               <div className={cx("termsGroupC")}>
                  {terms.simply.map((term, i) => (
                     <OffersTermPoint
                        key={i}
                        title={qOffersTermTemplates.data?.options.find((op) => op.value === term.title)?.label || ""}
                        isNumber={typeof term.default === "number"}
                        type={typeof term.default === "number" ? "bold-value" : "bold-title"}
                        value={term.default}
                        format="currency"
                     />
                  ))}
               </div>
               <div className={cx("customTermsC")}>
                  <span>Custom Terms</span>
                  <ul>
                     <li>Strike ln. 43 from PSA</li>
                     <li>Property can be assigned to entity owned or operated by Simply Homes</li>
                  </ul>
               </div>
               <div className={cx("additionalC")}>
                  {terms.additional.map((term, i) => (
                     <span key={i}>{term}</span>
                  ))}
               </div>
            </div>
            <div className={cx("propertiesC")}>
               <span className={cx("title")}>Properties</span>
               <div className={cx("listDocsC")}>
                  {properties?.map((property, i) => (
                     <div className={cx("docs")} key={i}>
                        <OffersPropertiesSelection property={property} />
                        <div className={cx("filesC")}>
                           <span>Documents</span>
                           <div className={cx("input_search")}>
                              <SoSInputTitle
                                 value={""}
                                 type="text"
                                 handleValue={() => {}}
                                 icon="search"
                                 placeholder="Search a file"
                              />
                           </div>
                           <FileDocument />
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
         <div className={cx("buttonsC")}>
            <SHrSosButton
               type="filled"
               buttonProps={{
                  onClick: () => offersDispatch({ overwrite: { showCreateAndUpdateOfferModal: true, oid } }),
               }}
            >
               Edit
            </SHrSosButton>
            <SHrSosButton type="tonal" buttonProps={{ className: cx("createEmail") }}>
               Create Email
            </SHrSosButton>
            <SHrSosButton type="positive">Create Contract</SHrSosButton>
            <SHrSosButton type="tonal" buttonProps={{ className: cx("sendOffer") }}>
               Send Offer
            </SHrSosButton>
            <div className={cx("previewC")}>
               <div className={cx("headingC")}>
                  <span>
                     <b>Preview</b>
                  </span>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/offer/open_in_full_black.svg")} alt="" />
                  </div>
               </div>
               <div className={cx("imgOfferC")}>
                  <img src={CF_CDN_URL("/assets/offer/offer_example.png")} alt="" />
               </div>
            </div>
         </div>
      </div>
   );
};

const FileDocument = () => {
   return (
      <div className={cx("fileC")}>
         <div className={cx("fileNameC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_validator/picture_as_pdf_black.svg")} alt="" />
            </div>
            <span>File name.pdf</span>
         </div>

         <div className={cx("img-icon")}>
            <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
         </div>
      </div>
   );
};
