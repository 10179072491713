import { createContext, useContext, useReducer } from "react";
import { useCredential } from "../hooks/auth/useCredential";
import { PViProvider } from "./PViContext";
import { OffersProvider } from "./OffersContext";
import { TSoS_GetUserProfile } from "../hooks/queries/user/useQSOS_Profile";

class CAppState {
   credential: string = "";
   isGoogleMapsLoaded: boolean = false;
   currentUser: TSoS_GetUserProfile = {
      email: "",
      name: "",
      picture: "",
      roles: [],
      subscribed_apps: [],
   };
}
const AppContext = createContext({ appState: new CAppState(), appDispatch: (_: TAppAction) => {} });
type TAppAction = { overwrite: TAppOverwrite };
type TAppOverwrite = Partial<CAppState>;
const appReducer = (state: CAppState, action: TAppAction): CAppState => {
   if ("overwrite" in action) return { ...state, ...action.overwrite };
   // switch (action.type) {
   //     default:
   //         break;
   // }
   return { ...state };
};

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
   const { credential } = useCredential();
   const [appState, appDispatch] = useReducer(appReducer, { ...new CAppState(), credential });
   return (
      <AppContext.Provider value={{ appState, appDispatch }}>
         <PViProvider>
            <OffersProvider>{children}</OffersProvider>
         </PViProvider>
      </AppContext.Provider>
   );
};
export const useAppContext = () => {
   const context = useContext(AppContext);
   const appOverwrite = (overwrite: Partial<CAppState>) => context.appDispatch({ overwrite });
   return { ...context, appOverwrite };
};
