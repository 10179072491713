import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useMPVi_Source_CreateSource = ({ onSuccess }: TUseMPVi_Source_CreateSource) => {
   const { sosAxios } = useSosAxios();

   const mutation = useMutation({
      onSuccess,
      mutationFn: (p: CreateSourceParams) => sosAxios.post(`/property-viability/properties/sources`, { ...p }),
   });
   return { ...mutation };
};

export type TUseMPVi_Source_CreateSource = {
   onSuccess: () => void;
};

export type CreateSourceParams_Property_Unit = {
   bed: number;
   bath: number;
};

export type CreateSourceParams_Property = {
   address: string;
   price: number;
   cids: number[];
   units: CreateSourceParams_Property_Unit[];
};

export type CreateSourceParams = {
   source_source: string;
   cids: number[];
   properties: CreateSourceParams_Property[];
};
