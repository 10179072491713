import classNames from "classnames/bind";
import styles from "./PViHCVRentTab.module.scss";
import { useQPVi_Property } from "../../../../../../hooks/property-viability/queries/useQPVi_Property";
import { useSoSOptions_PVi_HousingAuthorities } from "../../../../../../hooks/queries/options/useQSoS_Options";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../../hooks/database/mutations/utils";
import { SoSDataPoint } from "../../../../../../components/SoSDataPoint/SoSDataPoint";
import { useState } from "react";
import { SHrSosButton } from "@simplyhomes/react/dist/components/common/SHrSosButton/SHrSosButton";
import { SHrPopup } from "@simplyhomes/react/dist/components/common/SHrPopup/SHrPopup";
import { PViUtilitiesAllowanceModal } from "../../../PViUtilitiesAllowanceModal/PViUtilitiesAllowanceModal";
import { CF_CDN_URL } from "../../../../../../utils/CF_CDN_URL";
import { useMPVi_Property_ResetExpenses } from "../../../../../../hooks/property-viability/mutates/underwritingModel/useMPVi_Property_ResetExpenses";
import { PViExpensesTable } from "../PViExpensesTable/PViExpensesTable";

const cx = classNames.bind(styles);

export const PViHCVRentTab = () => {
   const qPVi_Property = useQPVi_Property();

   const mPVi_ResetExpenses = useMPVi_Property_ResetExpenses();

   const { property: p, units = [] } = qPVi_Property.data || {};

   const pid_id = { column: "pid", id: p?.pid || "" };

   const qOptions_HAs = useSoSOptions_PVi_HousingAuthorities({ q: p?.pid });

   const db_P = dbcb("sos_properties", [pid_id], qPVi_Property.refetch);

   if (!p) return null;

   const { housing_authority_name, gross_rent_final, noi_monthly, noi_annually } = p || {};

   const getHousingAuthorityOptions = qOptions_HAs.data?.options || [];

   const housingAuthorityOptions = getHousingAuthorityOptions.map((option) => ({
      value: option.value,
      label: option.label,
   }));

   const total_hcv_rent_final = units.map((u) => u.hcv_rent_final).reduce((a, b) => a + b, 0);

   const total_utilities_tenant_paying_final = units
      .map((u) => u.utilities_tenant_paying_final)
      .reduce((a, b) => a + b, 0);

   const gross_rent_final_annually = gross_rent_final * 12;

   return (
      <div className={cx("housingAuthorityC")}>
         <div className={cx("infoC")}>
            <div className={cx("dataC")}>
               <SoSDataPoint
                  title="Housing Authority"
                  type="bold-title"
                  options={housingAuthorityOptions}
                  value={housing_authority_name}
                  database={db_P("housing_authority_id")}
               />
               <SoSDataPoint
                  type="bold-title"
                  format="currency"
                  value={total_hcv_rent_final}
                  title="Total HCV Payment Standard"
               />
               {/* <SoSDataPoint type="bold-value" format="currency" value={1785} isMocked icon="local_activity" /> */}
            </div>

            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>

            <div className={cx("dataC")}>
               <SoSDataPoint
                  type="bold-value"
                  format="currency"
                  value={total_utilities_tenant_paying_final}
                  title="Total Utility Deduction"
                  isHistory
               />
            </div>

            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>

            <div className={cx("dataC")}>
               <SoSDataPoint
                  type="bold-value"
                  format="currency"
                  value={gross_rent_final}
                  additionalInfor="/month"
                  title="Estimated Gross Rent"
                  isHistory
               />
               <SoSDataPoint
                  type="bold-value"
                  format="currency"
                  value={gross_rent_final_annually}
                  additionalInfor="/year"
               />
            </div>

            <div className={cx("dataC", "bg")}>
               <SoSDataPoint
                  type="bold-value"
                  format="currency"
                  value={noi_monthly}
                  additionalInfor="/month"
                  title="NOI"
                  isHistory
               />
               <SoSDataPoint type="bold-value" format="currency" value={noi_annually} additionalInfor="/year" />
            </div>
         </div>
         {units
            .sort((a, b) => a.uid - b.uid)
            .map((_, i) => (
               <UnitInformation key={i} unitIndex={i} />
            ))}

         <div className={cx("tableExpensesC")}>
            <SHrSosButton
               type="text"
               loading={mPVi_ResetExpenses.isPending}
               buttonProps={{ className: cx("resetBtn"), onClick: () => mPVi_ResetExpenses.mutate({}) }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/history_info500.svg")} alt="" />
               </div>
               Reset Table
            </SHrSosButton>
            <div className={cx("tableContainer")}>
               <PViExpensesTable />
            </div>
         </div>
      </div>
   );
};

type UnitInformationProps = {
   unitIndex: number;
};

const UnitInformation = ({ unitIndex }: UnitInformationProps) => {
   const qPVi_Property = useQPVi_Property();

   const { units = [] } = qPVi_Property.data || {};

   const [isOpenModal, setIsOpenModal] = useState(false);

   const u = units[unitIndex];

   if (!u) return null;

   const db_U = dbcb("sos_units", [{ column: "uid", id: u.uid }], () => qPVi_Property.refetch());

   return (
      <div className={cx("unitContainer")}>
         <div className={cx("optionC")}>
            <div className={cx("valuesC")}>
               <SoSDataPoint
                  type="bold-title"
                  value={u.bed > 1 ? `${u.bed} beds` : `${u.bed} bed`}
                  title={`Unit ${unitIndex + 1}`}
                  icon="bedroom"
                  database={db_U("bed")}
               />
            </div>
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            <div className={cx("valuesC")}>
               <SoSDataPoint type="bold-title" value={u.hcv_rent_final} title="HCV Rent" format="currency" />
            </div>
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            <div className={cx("valuesC")}>
               <SoSDataPoint
                  type="bold-value"
                  format="currency"
                  value={u.utilities_tenant_paying_final}
                  title="Utility Deduction"
               />
            </div>
         </div>
         <div className={cx("utilityC")}>
            {/* <span>
               <b>Document</b>
            </span>
            <div className={cx("nameDocC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/insert_drive_file_info500.svg")} alt="" />
               </div>
               <span>Unit 1_Utility Allowance_2037 Sampson St, Blackridge, PA 15221, USA</span>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/download_neutral700.svg")} alt="" />
               </div>
            </div> */}
            <SHrSosButton type="text" buttonProps={{ className: cx("btn"), onClick: () => setIsOpenModal(true) }}>
               Utilities Allowance
            </SHrSosButton>
         </div>

         <SHrPopup show={isOpenModal} handleShow={setIsOpenModal} type="resize-right">
            <PViUtilitiesAllowanceModal onClose={() => setIsOpenModal(false)} unit={u} />
         </SHrPopup>
      </div>
   );
};
