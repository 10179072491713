import classNames from "classnames/bind";
import styles from "./PViSource.module.scss";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { SoSDataPoint } from "../../../../../components/SoSDataPoint/SoSDataPoint";
import { formatDate } from "../../../../OffersPage/OffersLeadCard/OffersLeadCard";
import { useQPVi_PropertiesBySource } from "../../../../../hooks/property-viability/queries/useQPVi_PropertiesBySource";
import { SHrSpinner } from "@simplyhomes/react";
import SoSSlider, { SoSSlider_Item } from "../../../../../components/SoSSlider/SoSSlider";
import { useSoSOptions_PVi_Source_Reject_Reasons } from "../../../../../hooks/queries/options/useQSoS_Options";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../hooks/database/mutations/utils";

const cx = classNames.bind(styles);

export const PViSource = () => {
   const qPVi_Property = useQPVi_Property();

   const qPViSourcerejectReason = useSoSOptions_PVi_Source_Reject_Reasons();

   const getSourceRejectReasonOptions = qPViSourcerejectReason.data?.options || [];

   const sourceRejectReasonOptions = getSourceRejectReasonOptions.map((option) => ({
      value: option.value,
      label: option.label,
   }));

   const { property: p } = qPVi_Property.data || {};

   const qPVi_PropertiesBySource = useQPVi_PropertiesBySource({ sid: p?.source.sid || 0 });

   if (!p) return null;

   const otherPropertiesSumitted = qPVi_PropertiesBySource.data?.properties.filter(
      (property) => property.pid !== p.pid
   );

   const propertiesSliderData: SoSSlider_Item[] =
      otherPropertiesSumitted?.map((property) => ({
         type: "source-property-card",
         pid: property.pid,
         sid: p.source.sid,
      })) || [];

   const db_P = dbcb("sos_properties", [{ column: "pid", id: p.pid }], () => qPVi_Property.refetch());

   return (
      <div className={cx("container")}>
         <div className={cx("submissionC")}>
            <span className={cx("heading")}>{p.source.source}</span>
            <div className={cx("infoC")}>
               <SoSDataPoint type="bold-title" value={formatDate(p.source.created_at)} title="Date added" />
               <SoSDataPoint type="bold-title" value={p.asking_price} title="Asking Price" format="currency" />
               <SoSDataPoint type="bold-title" value={p.renovation_cost} title="Estimated Reno" format="currency" />
               <SoSDataPoint type="bold-title" value={"0 Passed - 2 Failed"} title="Previous Inspection" isMocked />
            </div>
            <div className={cx("infoC")}>
               <SoSDataPoint type="bold-title" value={"Date"} title="Date Rejected" isMocked />
               <SoSDataPoint
                  type="bold-title"
                  value={p.reasons}
                  options_isMulti
                  title="Rejection Reason"
                  options={sourceRejectReasonOptions}
                  database={db_P("reasons")}
               />
               <SoSDataPoint type="bold-title" value={"Listing Agent/Seller"} title="Listing Agent/Seller" isMocked />
            </div>

            <hr className={cx("divider")} />
            <span className={cx("heading")}>Other properties submitted from this source</span>
            <div className={cx("propertiesC")}>
               {qPVi_PropertiesBySource.isLoading ? (
                  <div className={cx("loading")}>
                     <SHrSpinner sideLength="32px" /> <span>Loading Properties...</span>
                  </div>
               ) : !otherPropertiesSumitted?.length ? (
                  <span className={cx("no-properties")}>No other properties.</span>
               ) : (
                  <SoSSlider items={propertiesSliderData}></SoSSlider>
               )}
               <div className={cx("sliderPropertiesC")}></div>
            </div>
         </div>
      </div>
   );
};
