import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";

export const useMConvo_CreateThread = ({ onSuccess, onError }: TUseMConvo_CreateThreadProps) => {
   const { sosAxios } = useSosAxios();

   return useMutation({
      onSuccess,
      onError,
      mutationFn: ({ id, tags, content, title }: createThreadParams) =>
         sosAxios
            .post(`/conversations/threads/`, { id, title, content, tags })
            .then<TConversations_CreateThread_Response>(({ data }) => data),
   });
};


type createThreadParams = {
   id: string;
   title: string;
   content: string;
   tags: string[];
};

type TUseMConvo_CreateThreadProps = {
   onSuccess: (data: TConversations_CreateThread_Response) => void;
   onError: (message: string) => void;
};

export type TConversations_CreateThread_Response = { tid: number };
