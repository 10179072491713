import classNames from "classnames/bind";
import styles from "./PViUnitDetailsTab.module.scss";
import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
import { SoSDataPoint } from "../../../../../components/SoSDataPoint/SoSDataPoint";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { SoSConfirmWrapper } from "../../../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../hooks/database/mutations/utils";
import {
   useSoSOptions_PVi_AccessDetailsObjectType,
   useSoSOptions_PVi_CurrentLeaseType,
   useSoSOptions_PVi_ElectricalType,
   useSoSOptions_PVi_HousingAuthorities,
} from "../../../../../hooks/queries/options/useQSoS_Options";
import { mapOptions } from "../PVipropertyDetailsTab/PVipropertyDetailsTab";
import { TPgSoS_SoSUtilitiesAllowances_Schema_Data } from "@simplyhomes/utils/dist/types/pg/sos/utilities-allowances";
import { TPgSoS_SoSUnits_Schema_UtilitiesConfig } from "@simplyhomes/utils/dist/types/pg/sos/units";
import { TSoSInputSelect_option } from "../../../../../components/common/SoSInputSelect/SoSInputSelect";
import { useMDatabase_UpdateValue } from "../../../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { useMPVi_Property_CreateUnit } from "../../../../../hooks/property-viability/mutates/units/useMPVi_Property_CreateUnit";
import { useMPVi_Property_DeleteUnit } from "../../../../../hooks/property-viability/mutates/units/useMPVi_Property_DeleteUnit";

const cx = classNames.bind(styles);

export const PViUnitDetailsTab = () => {
   const { property, units = [] } = useQPVi_Property().data || {};

   const mPVi_Prop_CreateUnit = useMPVi_Property_CreateUnit();

   if (!property) return null;

   return (
      <div>
         {units.map((_, i) => (
            <div className={cx("unitDetailTabC")}>
               <PViUnitDetail unitIndex={i} key={i} />
            </div>
         ))}
         <div className={cx("addPhotosC")}>
            <SHrSosButton
               buttonProps={{ className: cx("btnAdd"), onClick: () => mPVi_Prop_CreateUnit.mutate() }}
               type="tonal"
               loading={!!mPVi_Prop_CreateUnit.isMutating}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
               </div>
               <span>Add Unit</span>
            </SHrSosButton>
         </div>
      </div>
   );
};

const PViUnitDetail = ({ unitIndex }: { unitIndex: number }, { leasingIndex }: { leasingIndex: number }) => {
   const qPVi_Property = useQPVi_Property();
   const { units = [], property: p } = qPVi_Property.data || {};

   const qPViHousingAuthorities = useSoSOptions_PVi_HousingAuthorities({ q: p?.pid });

   const qPViAccessDetailObjectType = useSoSOptions_PVi_AccessDetailsObjectType();

   const qPViCurrentLeaseType = useSoSOptions_PVi_CurrentLeaseType();

   const qPViElectricalType = useSoSOptions_PVi_ElectricalType();

   const getAccessDetailObjectTypeOptions = qPViAccessDetailObjectType.data?.options || [];

   const getHousingAuthorityOptions = qPViHousingAuthorities.data?.options || [];

   const getCurrentLeaseTypeOptions = qPViCurrentLeaseType.data?.options || [];

   const getElectricalTypeOptions = qPViElectricalType.data?.options || [];

   const accessDetailTypeOptions = mapOptions(getAccessDetailObjectTypeOptions);

   const currentLeaseTypeOptions = mapOptions(getCurrentLeaseTypeOptions);

   const unit = units[unitIndex];
   const mPVi_Prop_DeleteUnit = useMPVi_Property_DeleteUnit({ uid: unit?.uid || 0 });

   const db_U = dbcb("sos_units", [{ column: "uid", id: unit.uid }], () => qPVi_Property.refetch());

   const mutateDB = useMDatabase_UpdateValue(db_U("utilities_config"));
   console.log({ unit });
   if (!p) return null;

   const pid_id = { column: "pid", id: p?.pid || "" };

   if (!unit) return null;

   const db_L = dbcb("sos_leasings", [{ column: "lid", id: unit.leasing?.lid }], () => qPVi_Property.refetch());
   const db_P = dbcb("sos_properties", [pid_id], qPVi_Property.refetch);

   const handleSetConfig = (config: TPgSoS_SoSUnits_Schema_UtilitiesConfig) => {
      mutateDB.mutate({ value: JSON.stringify(config) });
   };

   return (
      <div className={cx("container")} key={unitIndex}>
         <div className={cx("heading-title")}>
            <span>Unit {unitIndex + 1}</span>
            {mPVi_Prop_DeleteUnit.isMutating ? (
               <SHrSpinner />
            ) : (
               units.length > 1 && (
                  <SoSConfirmWrapper
                     title="Are you sure you want to remove this unit out this property?"
                     content="This action allows to remove this unit out this property."
                     type="default"
                     onCancel={() => {}}
                     onConfirm={() => mPVi_Prop_DeleteUnit.mutate()}
                  >
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
                     </div>
                  </SoSConfirmWrapper>
               )
            )}
         </div>
         <div className={cx("unitDetailC")}>
            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Rooms & Stories</b>
                  </span>
               </div>
               <div className={cx("unitDataC")}>
                  <SoSDataPoint
                     title="Bedrooms"
                     type="bold-title"
                     value={unit.bed}
                     affix="beds"
                     icon="bedroom"
                     database={db_U("bed")}
                  />
                  <SoSDataPoint
                     title="Bathrooms"
                     type="bold-title"
                     value={unit.bath}
                     affix="baths"
                     icon="bathroom"
                     database={db_U("bath")}
                  />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Rent</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <SoSDataPoint title="HCV Rent" type="bold-title" value={unit.hcv_rent_final} format="currency" />
                  <SoSDataPoint
                     title="HCV Total Utility Allowance"
                     type="bold-title"
                     value={unit.utilities_tenant_paying_final}
                     format="currency"
                  />
                  <SoSDataPoint
                     title="HCV Estimated Gross Rent"
                     type="bold-title"
                     value={unit.gross_rent_final}
                     format="currency"
                  />
                  <SoSDataPoint
                     title="Housing Authority"
                     type="bold-title"
                     options={getHousingAuthorityOptions}
                     value={p.housing_authority_name}
                     database={db_P("housing_authority_id")}
                  />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Leasing</b>
                  </span>
               </div>

               <div className={cx("leasingC")}>
                  {unit.leasing && (
                     <div className={cx("leasingLeftC")}>
                        <SoSDataPoint title="Current Lease" type="bold-title" value={1500} isMocked format="currency" />
                        <SoSDataPoint
                           title="Current Lease Rent"
                           type="bold-title"
                           value={1500}
                           isMocked
                           format="currency"
                        />
                        <SoSDataPoint
                           title="Current Lease End"
                           type="bold-title"
                           value={1500}
                           isMocked
                           format="currency"
                        />
                        <SoSDataPoint
                           title="Current Lease Type"
                           type="bold-title"
                           value={unit.leasing.type || "N/A"}
                           options={currentLeaseTypeOptions}
                           database={db_L("type")}
                        />
                     </div>
                  )}

                  <div className={cx("leasingRightC")}>
                     <div className={cx("inspectionAsset")}>
                        <div className={cx("headingC")}>
                           <span>
                              <b>Initial SOW</b>
                           </span>
                        </div>

                        <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                           Upload a file
                        </SHrSosButton>

                        <div className={cx("linkC")}>
                           <div className={cx("linkUploadC")}>
                              <div className={cx("img-icon")}>
                                 <img src={CF_CDN_URL("/assets/property_validator/picture_as_pdf_black.svg")} alt="" />
                              </div>
                              <span>File name.pdf</span>
                              <div className={cx("linkIconsC")}>
                                 <div className={cx("img-icon")}>
                                    <img
                                       src={CF_CDN_URL(
                                          "/assets/property_validator/drive_file_rename_outline_black.svg"
                                       )}
                                       alt=""
                                    />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/delete_black.svg")} alt="" />
                                 </div>
                              </div>
                           </div>
                           <div className={cx("linkUploadC")}>
                              <div className={cx("img-icon")}>
                                 <img
                                    src={CF_CDN_URL("/assets/property_validator/insert_drive_file_black.svg")}
                                    alt=""
                                 />
                              </div>
                              <span>File name.pdf</span>
                              <div className={cx("linkIconsC")}>
                                 <div className={cx("img-icon")}>
                                    <img
                                       src={CF_CDN_URL(
                                          "/assets/property_validator/drive_file_rename_outline_black.svg"
                                       )}
                                       alt=""
                                    />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/delete_black.svg")} alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Utilities Configuration</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  {p.utilities_allowances &&
                     Object.entries(p.utilities_allowances).map(([groupTitle, groupData], i) => (
                        <UtilitiesGroup
                           groupData={groupData}
                           groupTitle={groupTitle}
                           config={unit.utilities_config}
                           key={groupTitle + i}
                           index={i}
                           setConfig={(c) => handleSetConfig(c)}
                        />
                     ))}
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>---?</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <SoSDataPoint
                     title="Electric Type"
                     type="bold-title"
                     options={getElectricalTypeOptions}
                     value={1500}
                     format="currency"
                     isMocked
                  />
                  <SoSDataPoint title="Plumbing Type" type="bold-title" value={1500} isMocked format="currency" />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Access Details</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <SoSDataPoint
                     title="Type"
                     type="bold-title"
                     options={accessDetailTypeOptions}
                     value={unit.access_detail_type || "N/A"}
                     database={db_U("access_detail_type")}
                  />
                  <SoSDataPoint
                     title="Code"
                     type="bold-title"
                     value={unit.access_detail_code}
                     database={db_U("access_detail_code")}
                  />
                  <SoSDataPoint title="Owner" type="bold-title" value={1500} isMocked format="currency" />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Refrigerator Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <SoSDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />

                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Stove Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <SoSDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />
                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Microwave Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <SoSDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />
                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>HVAC Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <SoSDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />
                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <hr className={cx("divide-hr")} />
         </div>
      </div>
   );
};
type UtilitiesGroupParams = {
   groupTitle: string;
   groupData: TPgSoS_SoSUtilitiesAllowances_Schema_Data[string];
   config: TPgSoS_SoSUnits_Schema_UtilitiesConfig;
   setConfig: (c: TPgSoS_SoSUnits_Schema_UtilitiesConfig) => void;
   index: number;
};
const UtilitiesGroup = ({ groupData, groupTitle, config, setConfig, index }: UtilitiesGroupParams) => {
   if (!groupData) return null;
   const { lineItems, isMultiSelect } = groupData;
   const value = config.filter((o) => o.groupTitle === groupTitle).map((o) => o.lineTitle);
   const handleSetConfig = (o: TSoSInputSelect_option[]) => {
      const Options = o.length === 0 ? o : isMultiSelect ? o : [o[o.length - 1]];
      const newConfig = [
         ...config.filter((c) => c.groupTitle !== groupTitle),
         ...Options.map((o) => ({ lineTitle: o.value, groupTitle })),
      ];
      setConfig(newConfig);
   };
   return (
      <SoSDataPoint
         key={index}
         title={groupTitle}
         type="bold-title"
         options_isMulti
         value={value}
         options={lineItems.map((o) => o.title) || []}
         onOptionSelect={(o) => handleSetConfig(o)}
      />
   );
};
